<!-- eslint-disable max-len -->
<template>
  <VideoPlayer
    ref="videoPlayer"
    :src
    :poster="props.poster ?? undefined"
    controls
    :class="props.class ?? ''"
    @play="onPlay"
    @mounted="onPlayerMounted"
    @progress="onProgress"
    @ended="onEnded"
  >
    <template #default="{ player, state }">
      <button
        v-show="bigPlayButtonVisibility"
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        @click="state.playing ? player.pause() : player.play()"
      >
        <VideoPlayIcon class="text-black/75 hover:text-black/50 duration-200"></VideoPlayIcon>
      </button>
    </template>
  </VideoPlayer>
</template>

<script setup lang="ts">
import { VideoPlayer } from '@videojs-player/vue'
import type { VideoPlayerState } from '@videojs-player/vue'
import type { VideoJsPlayer } from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-ads'
import 'videojs-ima'
import { parseInt } from 'lodash-es'
import VideoPlayIcon from '~/components/icons/video-play-icon.vue'

const props = defineProps<{
  src: string
  poster?: string | null
  class?: string
  title: string
  movieTitle: string
}>()

const bigPlayButtonVisibility = ref(true)
const videojsPlayer = ref<VideoJsPlayer | null>(null)
const playerState = ref<VideoPlayerState | null>(null)
const playedOnce = ref(false)
const gtm = useGtm()
const config = useRuntimeConfig()

watch(
  () => props.src,
  () => {
    resetAdTracking()
    bigPlayButtonVisibility.value = true
    playedOnce.value = false
    videojsPlayer.value?.pause()
  }
)

function onPlayerMounted({ player, state }: { player: VideoJsPlayer; state: VideoPlayerState }) {
  videojsPlayer.value = player
  playerState.value = state

  const tagUrl = new URL('https://pubads.g.doubleclick.net/gampad/ads')

  const urlQuery = {
    // This shouldn't change, so we can hard code it.
    iu: '/215787434/Mediafilm.ca/Mediafilm.ca',
    sz: '1x1|480x360|640x360|640x480',
    cust_params: `film=${props.movieTitle}`,
    description_url: window.location.href,
    correlator: Math.floor(Math.random() * 1056561),
    ciu_szs: '',
    gdfp_req: 1,
    output: 'vast',
    unviewed_position_start: 1,
    env: 'vp',
    impl: 's'
  }

  for (const key in urlQuery) {
    tagUrl.searchParams.set(key, urlQuery[key as unknown as keyof typeof urlQuery] as string)
  }

  const imaOptions = {
    adTagUrl: tagUrl.toString(),
    debug: config.public.isDev,
    disableAdControls: false,
    showControlsForJSAds: true
  }

  videojsPlayer.value?.ima(imaOptions)
}

defineExpose({
  player: videojsPlayer,
  state: playerState
})

let sentStartEvent = false

async function onPlay() {
  bigPlayButtonVisibility.value = false

  await playAd().then(() => {
    // Log video start event
    if (!sentStartEvent) {
      gtm?.trackEvent({
        event: 'video_start',
        video_title: props.title,
        video_percent: 0,
        video_duration: playerState.value?.duration,
        video_current_time: 0
      })
      sentStartEvent = true
    }
    videojsPlayer.value?.play()

    if (!playedOnce.value) {
      videojsPlayer.value?.currentTime(0)
    }
    playedOnce.value = true
  })
}

let sentEndEvent = false

function onEnded() {
  if (!sentEndEvent) {
    gtm?.trackEvent({
      event: 'video_complete',
      video_title: props.title,
      video_percent: 100,
      video_duration: playerState.value?.duration,
      video_current_time: playerState.value?.duration
    })
    sentEndEvent = true
  }
}

const progressEventPoints = {
  25: false,
  50: false,
  75: false
}

function playAd(): Promise<void> {
  return new Promise((resolve) => {
    videojsPlayer.value?.ima?.requestAds()
    videojsPlayer.value?.on('ads-ad-ended', () => resolve())
  })
}

function resetAdTracking() {
  sentStartEvent = false
  sentEndEvent = false
  Object.keys(progressEventPoints).forEach((key) => (progressEventPoints[key] = false))
}

function onProgress() {
  if (playerState.value) {
    const progress = (playerState.value.currentTime * 100) / playerState.value.duration

    for (const progressKey in progressEventPoints) {
      const typedKey = progressKey as unknown as keyof typeof progressEventPoints

      if (!progressEventPoints[typedKey] && progress >= typedKey) {
        gtm?.trackEvent({
          event: 'video_progress',
          video_title: props.title,
          video_percent: parseInt(progressKey),
          video_duration: playerState.value.duration,
          video_current_time: playerState.value.currentTime
        })
        progressEventPoints[typedKey] = true
      }
    }
  }
}
</script>

<style>
.video-js video {
  @apply object-cover;
}

.video-js .vjs-big-play-button {
  @apply hidden;
}

.video-js .vjs-poster {
  @apply bg-cover rounded-md;
}

.video-js .vjs-tech {
  @apply rounded-md;
}

.video-js .vjs-control-bar {
  @apply rounded-b-md z-20;
}

/**
 * Copyright 2014 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.ima-ad-container {
  top: 0em;
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
}

/* Move overlay if user fast-clicks play button. */
.video-js.vjs-playing .bumpable-ima-ad-container {
  margin-top: -4em;
}

/* Move overlay when controls are active. */
.video-js.vjs-user-inactive.vjs-playing .bumpable-ima-ad-container {
  margin-top: 0em;
}

.video-js.vjs-paused .bumpable-ima-ad-container,
.video-js.vjs-playing:hover .bumpable-ima-ad-container,
.video-js.vjs-user-active.vjs-playing .bumpable-ima-ad-container {
  margin-top: -4em;
}

.ima-controls-div {
  bottom: 0em;
  height: 1.4em;
  position: absolute;
  overflow: hidden;
  display: none;
  opacity: 1;
  background-color: rgba(7, 20, 30, 0.7);
  background: -moz-linear-gradient(
    bottom,
    rgba(7, 20, 30, 0.7) 0%,
    rgba(7, 20, 30, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0%, rgba(7, 20, 30, 0.7)),
    color-stop(100%, rgba(7, 20, 30, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    bottom,
    rgba(7, 20, 30, 0.7) 0%,
    rgba(7, 20, 30, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    bottom,
    rgba(7, 20, 30, 0.7) 0%,
    rgba(7, 20, 30, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    bottom,
    rgba(7, 20, 30, 0.7) 0%,
    rgba(7, 20, 30, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(to top, rgba(7, 20, 30, 0.7) 0%, rgba(7, 20, 30, 0) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#0007141E',
      endColorstr='#07141E',GradientType=0 ); /* IE6-9 */
}

.ima-controls-div.ima-controls-div-showing {
  height: 3.7em;
}

.ima-countdown-div {
  height: 1em;
  color: #ffffff;
  text-shadow: 0 0 0.2em #000;
  cursor: default;
}

.ima-seek-bar-div {
  top: 1.2em;
  height: 0.3em;
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
}

.ima-progress-div {
  width: 0em;
  height: 0.3em;
  background-color: #ecc546;
}

.ima-play-pause-div,
.ima-mute-div,
.ima-slider-div,
.ima-fullscreen-div {
  width: 2.33em;
  height: 1.33em;
  top: 0.733em;
  left: 0em;
  position: absolute;
  color: #cccccc;
  font-size: 1.5em;
  line-height: 2;
  text-align: center;
  font-family: VideoJS;
  cursor: pointer;
}

.ima-mute-div {
  left: auto;
  right: 5.667em;
}

.ima-slider-div {
  left: auto;
  right: 2.33em;
  width: 3.33em;
  height: 0.667em;
  top: 1.33em;
  background-color: #555555;
}

.ima-slider-level-div {
  width: 100%;
  height: 0.667em;
  background-color: #ecc546;
}

.ima-fullscreen-div {
  left: auto;
  right: 0em;
}

.ima-playing:before {
  content: '\00f103';
}

.ima-paused:before {
  content: '\00f101';
}

.ima-playing:hover:before,
.ima-paused:hover:before {
  text-shadow: 0 0 1em #fff;
}

.ima-non-muted:before {
  content: '\00f107';
}

.ima-muted:before {
  content: '\00f104';
}

.ima-non-muted:hover:before,
.ima-muted:hover:before {
  text-shadow: 0 0 1em #fff;
}

.ima-non-fullscreen:before {
  content: '\00f108';
}

.ima-fullscreen:before {
  content: '\00f109';
}

.ima-non-fullscreen:hover:before,
.ima-fullscreen:hover:before {
  text-shadow: 0 0 1em #fff;
}
</style>
