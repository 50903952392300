<template>
  <Galleria
    ref="galleriaRef"
    v-model:activeIndex="internalActiveIndex"
    v-model:visible="internalVisible"
    :value="items"
    :circular="true"
    :full-screen="true"
    :show-thumbnails="false"
    :show-item-navigators="items && items.length > 1"
    :responsive-options="galleriaResponsiveOptions"
    :container-style="'max-width:90%;max-height:95vh !important; width:100%;'"
    :pt="{
      mask: {
        class: ['@container ']
      },
      itemcontainer: {
        class: ['flex justify-center items-center']
      },
      previousitembutton: {
        class: [isMobile ? '!bg-black !top-auto' : '', ' !z-[1000]']
      },
      nextitembutton: {
        class: [isMobile ? '!bg-black !top-auto' : '']
      }
    }"
    :pt-options="{ mergeProps: true, mergeSections: true }"
    @update:visible="onVisibilityChange"
  >
    <template #header>
      <div class="mb-9 w-full flex justify-center">
        <span class="text-white text-xl font-medium w-[80%] @lg:!w-[calc((min(600px,60vh)*16)/9)]">
          {{ items[internalActiveIndex]?.title }}
          {{ props.titleSuffix ? `– ${props.titleSuffix}` : '' }}
        </span>
      </div>
    </template>
    <template #item="slotProps">
      <div
        :class="[
          '!w-[80%]  aspect-video @sm:!h-full @lg:!w-[calc((min(600px,60vh)*16)/9)] flex justify-center items-center',
          height < 890 ? '@sm:!h-[min(400px,60vh)]' : '@sm:!h-[min(700px,60vh)]'
        ]"
      >
        <CustomVideoPlayer
          ref="videoPlayer"
          :src="slotProps.item?.trailer"
          :poster="getPromoImgOrFallback(slotProps.item?.trailerPoster)"
          class="!w-full !h-[300px] @sm:!h-full rounded-lg mb-3 lg:mb-9 group"
          :title="slotProps.item?.title + (props.titleSuffix ? `– ${props.titleSuffix}` : '')"
          :movie-title="slotProps.item?.movieTitle || slotProps.item?.title"
        ></CustomVideoPlayer>
      </div>
    </template>
  </Galleria>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Galleria from 'primevue/galleria'
import CustomVideoPlayer from '~/components/video/custom-video-player.vue'
import type { TrailerMoviePreview as MovieTrailerPreviewType } from '~/types/movie'
import { galleriaResponsiveOptions } from '~/consts/general'
import { getPromoImgOrFallback } from '~/helpers/assets'
import { onClickOutside } from '@vueuse/core'

const { height } = useResponsive()
const galleriaRef = ref(null)
const { isMobile } = useResponsive()
const props = withDefaults(
  defineProps<{
    items:
      | Array<MovieTrailerPreviewType & { movieTitle: string }>
      | Ref<Array<MovieTrailerPreviewType & { movieTitle: string }>>
      | ComputedRef<Array<MovieTrailerPreviewType & { movieTitle: string }>>
    visible: boolean
    activeIndex: number
    titleSuffix?: string
  }>(),
  {
    visible: false
  }
)

const emit = defineEmits(['update:visible'])

const internalActiveIndex = ref(props.activeIndex)
const internalVisible = ref(props.visible)
watch(
  () => props.activeIndex,
  (newValue) => {
    internalActiveIndex.value = newValue
  }
)

watch(
  () => props.visible,
  (newValue) => {
    internalVisible.value = newValue
  }
)

const items = computed(() => {
  return Array.isArray(props.items) ? props.items : props.items.value
})

function onVisibilityChange(newVisibility: boolean) {
  internalVisible.value = newVisibility
  if (!newVisibility) {
    emit('update:visible', false)
  }
}
onClickOutside(galleriaRef, (event) => {
  if (event?.target?.classList?.value?.includes('p-galleria-mask')) {
    onVisibilityChange(false)
  }
})

const videoPlayer = ref<InstanceType<typeof CustomVideoPlayer> | null>(null)

defineExpose({
  videoPlayer
})
</script>

<style scoped></style>
