import { ref } from 'vue'

export function useTrailerGalleria() {
  const activeIndex = ref(0)
  const visible = ref(false)

  function activateGallery(index: number) {
    activeIndex.value = index
    visible.value = true
  }

  return {
    activeIndex,
    visible,
    activateGallery
  }
}
