<template>
  <svg
    :class="props.class"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <circle cx="30" cy="30" r="30" fill="currentColor" />
        <path
          d="M38.9333 30.8C39.4667 30.4 39.4667 29.6 38.9333 29.2L25.6 19.2C24.9408 18.7056 24 19.176 24 20V40C24 40.824 24.9408 41.2944 25.6 40.8L38.9333 30.8Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4'
  }
)
</script>
